.Main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>.top-bar {
        width: 100%;
        background-color: #ff5d73;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

        & .main-name-root {
            margin: 5px 10px;
            // color: white;
        }
    }

    &>.body {
        width: 100%;
        flex-grow: 1;
        min-height: 0;
        display: flex;
    }
}