body {
  margin: 0;
  font-family: 'Varela Round';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #363636;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Varela Round';
  src: local('Varela Round'), url(./assets/fonts/VarelaRound-Regular.ttf) format('truetype');
}