.Item {
    padding: 5px 5px !important;
    display: flex;
    align-items: center;
    color: white;

    &.selected {
        background-color: rgb(110, 110, 110)
    }

    &>.item-left-container {
        flex-grow: 1;
        display: flex;
        align-items: center;

        &>svg {
            fill: #ffc100;
        }

        &>.name {
            margin: 0 5px;
            padding: 0;
            flex-grow: 1;
            color: white;

            & fieldset {
                border-width: 1px;
            }

            &.Mui-disabled {
                & fieldset {
                    border: none;
                }
            }

            &>.MuiInputBase-input {
                padding: 5px;
                font-family: 'Varela Round';
            }
        }


    }

    &>.item-right-container {
        display: flex;
        align-items: center;

        &>.item-group-container {
            display: flex;
            align-items: center;
            color: rgb(143, 143, 143);

            &>svg {
                fill: rgb(143, 143, 143);
                margin-bottom: 2px;
                font-size: 1.1rem;
            }
        }

        &>.item-no-owner-container {
            display: flex;
            align-items: center;
            color: rgb(143, 143, 143);

            &>svg {
                fill: #ff5d73;
                font-size: 1.1rem;
            }
            &>span {
                color: #ff5d73;
            }
        }



        &>.cost {
            margin: 0 5px;
            color: white;
            padding-left: 5px;

            &>.MuiInputAdornment-root {
                margin: 0;

                &>p {
                    color: white;
                    font-family: 'Varela Round';
                }
            }

            & fieldset {
                border-width: 1px;
            }

            &.Mui-disabled {
                & fieldset {
                    border: none;
                }
            }

            &>.MuiInputBase-input {
                padding: 5px;
                width: 45px;
                font-family: 'Varela Round';
                text-align: right;
            }
        }
    }
}


@media only screen and (max-width: 450px) {
    .item-left-container>svg {
        display: none;
    }
}