.Person {
    background-color: rgb(73, 73, 73) !important;
    margin: 10px 5px;
    padding: 10px 0;
    display: flex;
    align-items: center;

    &.owner {
        background-color: rgb(110, 110, 110) !important;
    }

    &>.Person-icon-container {
        margin-right: 10px;

        &>svg {
            fill: #ffc100;
            width: 44px;
            height: 44px;
        }
    }

    &>.Person-text-container {
        flex-grow: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;

        &>.name {
            color: white;
            font-size: 1.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            user-select: none;
        }

        &>.money {
            color: rgb(192, 192, 192);
            font-size: 1.2rem;
            user-select: none;
        }
    }
}


@media only screen and (max-width: 450px) {
    .Person-icon-container {
        display: none;
    }

    .Person-text-container {
        text-align: center;
    }
}