.totals-container {
    margin-top: 10px;
    background-color: rgb(73, 73, 73) !important;
    $muted-color: rgb(192, 192, 192);

    &>.list {
        padding: 0;
    }

    & .subtotal-container {
        padding: 5px 5px !important;
        display: flex;


        &>.label {
            flex-grow: 1;
            color: $muted-color
        }

        &>.value {
            text-align: right;
            color: $muted-color
        }
    }

    & .tax-container {
        padding: 5px 5px !important;
        display: flex;


        &>.label {
            flex-grow: 1;
            color: $muted-color
        }

        &>.value {
            text-align: right;
            color: $muted-color
        }
    }

    & .tip-container {
        padding: 5px 5px !important;
        display: flex;


        &>.label {
            flex-grow: 1;
            color: $muted-color
        }

        &>.value {
            text-align: right;
            color: $muted-color
        }
    }

    & .total-container {
        padding: 5px 5px !important;
        display: flex;


        &>.label {
            flex-grow: 1;
            color: white
        }

        &>.value {
            text-align: right;
            color: white
        }
    }
}