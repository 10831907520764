.Intro {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &>.intro-image-container {
        width: 50%;
        display: flex;
        justify-content: center;
        justify-items: center;

        &>.intro-image {
            height: 60vmin;
            pointer-events: none;
            animation-name: pulse;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }

    &>.intro-text-container {
        width: 50%;
        font-size: 2rem;
        color: #dddddd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &>.intro-text-input {
            margin-top: 50px;
            width: 500px;

            & .intro-text-input {
                color: #dddddd;
                font-size: 1.5rem;
            }

            & .intro-submit-icon {
                background-color: rgb(90, 90, 90);
                width: 32px;
                height: 32px;
                border-radius: 3px;
                pointer-events: none;
                cursor: pointer;
                transition: all 0.2s linear;

                &.ready {
                    background-color: #ff5d73;
                    pointer-events: initial;
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 1350px) {
    .Intro {
        flex-direction: column;

        &>.intro-image-container {
            width: 100%;
            display: flex;
            justify-content: space-around;

            &>.intro-image {
                height: 50vmin;
                margin-bottom: 50px;
            }
        }


        &>.intro-text-container {
            width: 100%;
            font-size: 2rem;
            color: #dddddd;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            &>.intro-text-input {
                margin-top: 50px;
                width: #{"min(90%, 500px)"};

                & .intro-text-input {
                    color: #dddddd;
                    font-size: 1.5rem;
                }

                & .intro-submit-icon {
                    background-color: rgb(90, 90, 90);
                    width: 32px;
                    height: 32px;
                    border-radius: 3px;
                    pointer-events: none;
                    cursor: pointer;
                    transition: all 0.2s linear;

                    &.ready {
                        background-color: #ff5d73;
                        pointer-events: initial;
                    }
                }
            }
        }
    }
}